'use client';

import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';
import { BiHome } from 'react-icons/bi';
import { BsPerson } from 'react-icons/bs';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { Transition } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import LoginModal from '../../components/LoginModal';
import UserModal from '../../components/UserModal';
import classNames from 'classnames';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { getFirebaseApp } from '../../helpers/firebase';
import SearchModal from '@/components/SearchModal';
import { useRouter } from 'next/router';

const auth = getAuth(getFirebaseApp());

const BottomNavigator = () => {
  const [open, setOpen] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const router = useRouter();
  const language = router.locale as SupportedLanguages;
  const isSpanish = language === 'es';
  const [user] = useAuthState(auth);
  const [show, setShow] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleCloseSearchModal = () => {
    setShowSearchModal(false);
  };

  useLayoutEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const closeHeaderMenu = () => {
    setOpen(false);
  };

  const handleMenuClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  // Function to handle scrolling
  const handleScroll = useCallback(() => {
    if (window.scrollY >= lastScrollY && window.scrollY > 80) {
      setShow(false); // If scrolling down, hide the navigator
    } else {
      setShow(true); // If scrolling up, show the navigator
    }
    setLastScrollY(window.scrollY);
  }, [lastScrollY]);

  // Set up the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, lastScrollY]);

  if (!mounted || window.innerWidth > 768) {
    return null;
  }

  return (
    <>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ y: 100 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="z-50 text-white fixed left-0 right-0 bg-copper shadow-md p-4 justify-between  w-full flex px-8 h-16 rounded-t-2xl bottom-0"
          >
            <button
              className="text-white flex flex-col items-center justify-center"
              onClick={() => {
                setShowSearchModal(true);
              }}
            >
              <PiMagnifyingGlass />
              <p className="text-xs">{isSpanish ? 'Buscar' : 'Search'}</p>
            </button>
            <button
              className="text-white flex flex-col items-center justify-center"
              onClick={() => {
                router.push('/');
              }}
            >
              <BiHome />
              <p className="text-xs">Home</p>
            </button>
            <button
              className="text-white flex flex-col items-center justify-center"
              onClick={handleMenuClick}
            >
              <BsPerson />
              <p className="text-xs">{isSpanish ? 'Perfil' : 'Profile'}</p>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
      <SearchModal show={showSearchModal} onClose={handleCloseSearchModal} />
      <Transition
        appear
        show={open}
        enter="transition ease-in duration-150"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition ease-out duration-150"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
        className="absolute left-0 -bottom-20 w-full box-content"
      >
        <div
          className={classNames(
            'bg-white',
            'flex',
            'flex-col',
            'justify-center',
            'items-center',
            'rounded-xl',
            'absolute',
            '-top-16',
            'w-full',
            'max-w-xs',
            'overflow-hidden'
          )}
        >
          {user ? (
            <UserModal onClose={closeHeaderMenu} show={open} />
          ) : (
            <LoginModal onClose={closeHeaderMenu} show={open} />
          )}
        </div>
      </Transition>
    </>
  );
};

export default BottomNavigator;
